import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "../../styles/dsmApplication.scss";
import { Toolbar } from "primereact/toolbar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DropdownInfo } from "../../../../models/types";
import { location } from "../../../DSM-Monitor/types/types";
import { ToastServices } from "../../../../services/utils/toast-services";
import { DSMApplicationServices } from "../../../../services/dsm-application-services";
import { onCheckedChange, onInputChange } from "../../../../services/utils/input-services";
import { CreateDSMApplicationLocationDialog } from "./create-dsm-application-location-dialog";
import { SelectDSMApplicationLocationDialog } from "./select-dsm-application-location-dialog";
import { DSMApplicationLocationsDataTable } from "../data-tables/dsm-application-locations-data-table";
import { dsmApplication, dsmApplicationLocation, emptyDSMApplicationLocation } from "../../types/types";

export interface CreateDSMApplicationDialogProps {
    dsmApplication: dsmApplication;
    setDSMApplication: (dsmApplication: dsmApplication) => void;
    setDSMApplicationDialog: (showDSMApplicationDialog: boolean) => void;
    isEditingDSMApplication: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    hideDialog: () => void;
    dialogHeader: string;
}

export const CreateDSMApplicationDialog = (props: CreateDSMApplicationDialogProps) => {
    const dsmApplicationService = new DSMApplicationServices();
    const _toastService = new ToastServices();

    const [showNewLocationDialog, setShowNewLocationDialog] = useState<boolean>(false);
    const [showNewSelectLocationDialog, setShowNewSelectLocationDialog] = useState<boolean>(false);
    const [selectedState, setSelectedState] = useState<string>(null);
    const [selectedLocation, setSelectedLocation] = useState<dsmApplicationLocation>(emptyDSMApplicationLocation);
    const [selectedLocations, setSelectedLocations] = useState<DropdownInfo[]>([]);

    useEffect(() => {
        updateStateByDSMApplication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dsmApplication]);

    const updateCitiesByState = (stateName: string) => {
        setSelectedState(stateName)
        dsmApplicationService.getCitiesByState(stateName).then(
            (response) => {
                setSelectedLocations(response.data)
            },
            (error: any) => _toastService.showErrorRequestMessage(error));
    };

    const updateStateByDSMApplication = () => {
        dsmApplicationService.getStateByApplication(props.dsmApplication.dsmApplicationId).then(
            (response) => {
                if (response.data != null && response.data != "") {
                    updateCitiesByState(response.data)
                }
            },
            (error: any) => _toastService.showErrorRequestMessage(error));
    };

    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => props.onSubmit()} />
        </>
    )

    const leftToolbarSelectTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Localidade" icon="pi pi-plus" className="p-button-success" onClick={(e) => openNewSelectLocationDialog()} />
                </div>
            </React.Fragment>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Localidade" icon="pi pi-plus" className="p-button-success" onClick={(e) => openNewLocationDialog()} />
                </div>
            </React.Fragment>
        );
    }

    const closeNewSelectLocationDialog = () => {
        setShowNewSelectLocationDialog(false);
    }

    const openNewSelectLocationDialog = () => {
        setShowNewSelectLocationDialog(true);
    }

    const closeNewLocationDialog = () => {
        setShowNewLocationDialog(false);
    }

    const openNewLocationDialog = () => {
        setShowNewLocationDialog(true);
    }

    const addLocation = (location: location) => {

        let _dsm_application = { ...props.dsmApplication } as any;
        _dsm_application.locationId = location.locationId;

        props.setDSMApplication(_dsm_application);
        setSelectedLocation(location)
        closeNewLocationDialog();

    }

    const deleteLocation = (rowData: any) => {
        let locations = props.dsmApplication.dsmApplicationLocations.filter((x) => x.locationId !== rowData.locationId);

        let _dsm_application = { ...props.dsmApplication } as any;
        _dsm_application.dsmApplicationLocations = locations;

        props.setDSMApplication(_dsm_application);
    }

    const typeOptions = [
        { label: 'MWS', value: 'MWS' },
        { label: 'MQR', value: 'MQR' },
        { label: 'RED', value: 'RED' }
    ];

    const modoOptions = [
        { label: 'Master', value: 'master' },
        { label: 'Slave', value: 'slave' }
    ];

    return (
        <div className="p-grid crud-demo">
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={userDialogFooter} onHide={props.hideDialog}>
                    <span className="titulo-categoria">IDENTIFICAÇÃO:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field col-6">
                                <span>Nome*</span>
                                <InputText
                                    id="name"
                                    type="text"
                                    value={props.dsmApplication.dsmApplicationName}
                                    onChange={(e) => onInputChange(e, "dsmApplicationName", props.dsmApplication, props.setDSMApplication)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.dsmApplicationName })}
                                />
                                {props.submitted && !props.dsmApplication.dsmApplicationName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                            <div className="p-field col-6">
                                <span>Tipo*</span>
                                <Dropdown
                                    id="type"
                                    value={props.dsmApplication.type}
                                    options={typeOptions}
                                    onChange={(e) => onInputChange(e, "type", props.dsmApplication, props.setDSMApplication)}
                                    placeholder="Selecione um tipo"
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.type })}
                                />
                                {props.submitted && !props.dsmApplication.type && <small className="p-invalid">Escolha um tipo.</small>}
                            </div>

                            <div className="p-field col-6">
                                <span>Modo*</span>
                                <Dropdown
                                    id="type"
                                    value={props.dsmApplication.modo}
                                    options={modoOptions}
                                    onChange={(e) => onInputChange(e, "type", props.dsmApplication, props.setDSMApplication)}
                                    placeholder="Selecione um modo"
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.modo })}
                                />
                                {props.submitted && !props.dsmApplication.modo && <small className="p-invalid">Escolha um modo.</small>}
                            </div>

                            <div className="p-field col-6">
                                <span>Localidade*</span>
                                <SelectDSMApplicationLocationDialog
                                    visible={showNewSelectLocationDialog}
                                    closeDialog={closeNewSelectLocationDialog}
                                    setLocation={(location) => { addLocation(location); }}
                                    setSelectedLocations={(locations) => { setSelectedLocations(locations); }}
                                    isEditing={props.isEditingDSMApplication}
                                    setState={(state) => { setSelectedState(state); }}
                                />
                                <Toolbar className="p-mb-4" left={leftToolbarSelectTemplate} />
                                {selectedLocation.locationId && (
                                    <span>{selectedLocation.locationName + " - " + selectedState}</span>
                                )}
                                <div />
                            </div>
                        </div>
                    </div>
                    <span className="titulo-categoria">REDE:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field-checkbox col-4">
                                <Checkbox
                                    className="mr-2"
                                    inputId="usingDDNS"
                                    name="option"
                                    checked={props.dsmApplication.usingDDNS}
                                    onChange={(e) => onCheckedChange(e, "usingDDNS", props.dsmApplication, props.setDSMApplication)}
                                />
                                <span>DDNS</span>
                            </div>
                            <div className="p-field col-8">
                                <span>Endereço IP*</span>
                                <InputText
                                    id="ipAddress"
                                    type="text"
                                    value={props.dsmApplication.ipAddress}
                                    onChange={(e) => onInputChange(e, "ipAddress", props.dsmApplication, props.setDSMApplication)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.ipAddress })}
                                />
                                {props.submitted && !props.dsmApplication.dsmApplicationName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                        </div>
                    </div>
                    <span className="titulo-categoria">LOCAIS DE ATENDIMENTO:</span>
                    <div className={'categoria'}>
                        <CreateDSMApplicationLocationDialog
                            visible={showNewLocationDialog}
                            closeDialog={closeNewLocationDialog}
                            setSelectedLocations={(locations) => { setSelectedLocations(locations); }}
                            selectedLocations={selectedLocations}
                            dsmApplicationLocations={props.dsmApplication.dsmApplicationLocations}
                            isEditing={props.isEditingDSMApplication}
                        />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                        <DSMApplicationLocationsDataTable
                            dsmApplicationLocations={props.dsmApplication.dsmApplicationLocations}
                            deleteLocation={deleteLocation}
                        />
                    </div>
                    {props.submitted && props.dsmApplication.dsmApplicationLocations.length <= 0 && <small className="p-invalid">Selecione pelo menos uma localidade.</small>}
                </Dialog>
            </div>
        </div>
    )
};
